/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback } from 'react';
import parse from 'html-react-parser';
import {
  Article,
  ArticleImage,
  ArticleReference,
  ArticleSection,
} from '../../../types/Article';
import {
  AlertTriangle,
  Check,
  ChevronDown,
  Copy,
  Edit3,
  ExternalLink,
  ToggleLeft,
  ToggleRight,
  X,
} from 'react-feather';
import ReportArticle from '../ReportArticle';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Skeleton from '@mui/material/Skeleton';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import { hapticsImpactLight } from '../../../utils/haptics';
import {
  fetchArticle,
  fetchArticleVignettes,
  updateArticleSection,
} from '../../../services/library';
import { enqueueSnackbar } from 'notistack';
import JumpToMenu from '../JumpToMenu';
import { Vignette, VignetteContent } from '../../../types/Vignette';
import { copyToClipboard } from '../../../utils/copyToClipboard';
import EditVignette from '../../Study/Exam/EditVignette';
import { Flashcard, FlashcardContent } from '../../../types/Flashcard';
import EditFlashcard from '../../Study/EditFlashcard';
import {
  updateVignetteSuspendStatus,
  updateVignetteVariant,
} from '../../../services/vignettes';
import {
  updateFlashcardSuspendStatus,
  updateFlashcardVariant,
} from '../../../services/flashcards';

interface ArticlePageProps {
  articleId: string;
  isStudyPanel: boolean;
}

const ArticlePage: React.FC<ArticlePageProps> = ({
  articleId,
  isStudyPanel,
}) => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);

  const [activeArticle, setActiveArticle] = useState<Article>();
  const [articleLoading, setArticleLoading] = useState(false);

  const [reportArticleModalIsOpen, setReportArticleModalIsOpen] =
    useState(false);
  const [headings, setHeadings] = useState<{ title: string; id: string }[]>([]);
  const [jumpLoading, setJumpLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [linkedVignettes, setLinkedVignettes] = useState<Vignette[]>([]);

  const [isEditingVignette, setIsEditingVignette] = useState(false);
  const [activeVignette, setActiveVignette] = useState<Vignette>();
  const [activeVignetteVariant, setActiveVignetteVariant] =
    useState<VignetteContent>();
  const [isEditingFlashcard, setIsEditingFlashcard] = useState(false);
  const [activeFlashcard, setActiveFlashcard] = useState<Flashcard>();
  const [activeFlashcardVariant, setActiveFlashcardVariant] =
    useState<FlashcardContent>();

  const [currentArticleSectionId, setCurrentArticleSectionId] =
    useState<string>('');
  const [currentArticleSectionText, setCurrentArticleSectionText] =
    useState<string>('');

  // Load Article by ID
  useEffect(() => {
    if (articleId) {
      loadArticleById(articleId);
    }
  }, [articleId]);

  // Update vignettes after editting
  useEffect(() => {
    if (
      !isEditingVignette &&
      !isEditingFlashcard &&
      articleId &&
      currentUser?.role === 'admin'
    ) {
      getLinkedVignettes(articleId);
    }
  }, [isEditingVignette, isEditingFlashcard, articleId, currentUser]);

  // Load the Jump Menu
  useEffect(() => {
    if (activeArticle && !isStudyPanel) {
      setJumpLoading(true);
      const h2Elements = Array.from(
        document.querySelectorAll('.article_body_center_card_content h2')
      );
      const newHeadings = h2Elements.map((el) => ({
        title: el.textContent || '',
        id: el.id,
      }));
      setHeadings(newHeadings);
      setJumpLoading(false);
    }
  }, [activeArticle, isStudyPanel]);

  const loadArticleById = async (id: string) => {
    setArticleLoading(true);
    const article = await fetchArticle(id);
    if (article) {
      setActiveArticle(article);
    } else {
      alert('Article not found');
    }
    setArticleLoading(false);
    window.scrollTo(0, 0);
  };

  const scrollToSection = (id: string) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleArticleSectionTextChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCurrentArticleSectionText(event.target.value);
  };

  const submitArticleSectionUpdate = async () => {
    const updatedSection = {
      text: currentArticleSectionText,
      updatedAt: moment().toISOString(),
    };
    await updateArticleSection(currentArticleSectionId, updatedSection);
    enqueueSnackbar('Article section updated successfully.', {
      autoHideDuration: 3000,
    });
    setIsEditing(false);
    if (loadArticleById && activeArticle) {
      loadArticleById(activeArticle.id);
    }
  };

  const renderArticleSections = useCallback((sections: ArticleSection[]) => {
    sections.sort((a, b) => a.ord - b.ord);
    return (
      <>
        {sections.map((section, sectionIndex) => {
          const updatedText = parse(section.text, {
            replace: (domNode: any) => {
              if (
                'type' in domNode &&
                domNode.type === 'tag' &&
                'name' in domNode &&
                domNode.name === 'h2'
              ) {
                const element = domNode as {
                  attribs: { [key: string]: string };
                };
                element.attribs.id = section.id;
              } else if (domNode.attribs && domNode.attribs.href) {
                return (
                  <Link
                    to={domNode.attribs.href}
                    key={
                      domNode.attribs.href +
                      '-' +
                      sectionIndex +
                      '-' +
                      Math.floor(Math.random() * 10000) +
                      1
                    }
                    className="internal-link"
                  >
                    {domNode.children[0]?.data}
                  </Link>
                );
              }
            },
          });

          return (
            <section
              id={'spy-' + sectionIndex}
              className="article-section"
              key={sectionIndex}
            >
              {currentUser?.role === 'admin' && !currentUser?.demoMode && (
                <div className="admin-toolbar">
                  <button
                    className="button button--secondary button--report"
                    onClick={() => {
                      setIsEditing(true);
                      setCurrentArticleSectionText(section.text);
                      setCurrentArticleSectionId(section.id);
                    }}
                  >
                    <Edit3 />
                    Edit
                  </button>
                </div>
              )}
              {updatedText}
              {renderArticleSectionImages(section.images)}
              <div className="reference-container">
                {renderArticleSectionReferences(section.references)}
              </div>
            </section>
          );
        })}
      </>
    );
  }, []);

  const renderArticleSectionImages = useCallback((images: ArticleImage[]) => {
    if (!images || !images.length) return null;
    return (
      <div className="article-section-images">
        {images.map((image, i) => {
          return (
            <div key={i} className="article-image">
              <img src={image.src} alt={image.alt} />
              <div className="caption-credit">
                {image.caption && (
                  <div className="caption">{image.caption}</div>
                )}
                {image.credit && <div className="credit">{image.credit}</div>}
              </div>
            </div>
          );
        })}
      </div>
    );
  }, []);

  const renderArticleSectionReferences = useCallback(
    (references: ArticleReference[]) => {
      if (references.length <= 3) {
        return (
          <div className="article-section-references">
            {references.map((reference, i) => (
              <a
                href={reference.url}
                target="_blank"
                rel="noreferrer"
                key={i}
                className="reference"
              >
                <div className="reference_link">
                  <ExternalLink />
                </div>
                <div className="spacer">|</div>
                <div className="reference_top">
                  {reference.title !== 'No title found' ? (
                    <div className="title">{parse(reference.title)}</div>
                  ) : (
                    <div className="title"></div>
                  )}
                </div>
                <div className="reference_bottom">
                  <span className="journal">{parse(reference.journal)}</span> |{' '}
                  {reference.year}
                </div>
              </a>
            ))}
          </div>
        );
      } else {
        return (
          <div className="article-section-references">
            <div className="preview-row">
              {references.slice(0, 3).map((reference, i) => (
                <a
                  href={reference.url}
                  target="_blank"
                  rel="noreferrer"
                  key={i + '-' + reference.id}
                  className="reference"
                >
                  <div className="reference_link">
                    <ExternalLink />
                  </div>
                  <div className="spacer">|</div>
                  <div className="reference_top">
                    {reference.title !== 'No title found' ? (
                      <div className="title">{parse(reference.title)}</div>
                    ) : (
                      <div className="title"></div>
                    )}
                  </div>
                  <div className="reference_bottom">
                    <span className="journal">{parse(reference.journal)}</span>{' '}
                    | {reference.year}
                  </div>
                </a>
              ))}
            </div>
            <Accordion className="reference-accordion">
              <AccordionSummary
                aria-controls="references-content"
                id="references-header"
              >
                <div className="button--see-all collapsed">
                  Show All References
                </div>
                <div className="button--see-all expanded">
                  Collapse References
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="article-section-references">
                  {references.slice(3).map((reference, i) => (
                    <a
                      href={reference.url}
                      target="_blank"
                      rel="noreferrer"
                      key={i + '-' + reference.id}
                      className="reference"
                    >
                      <div className="reference_link">
                        <ExternalLink />
                      </div>
                      <div className="spacer">|</div>
                      <div className="reference_top">
                        {reference.title !== 'No title found' ? (
                          <div className="title">{parse(reference.title)}</div>
                        ) : (
                          <div className="title"></div>
                        )}
                      </div>
                      <div className="reference_bottom">
                        <span className="journal">
                          {parse(reference.journal)}
                        </span>{' '}
                        | {reference.year}
                      </div>
                    </a>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        );
      }
    },
    []
  );

  const renderLoadingFrames = () => {
    return (
      <>
        <Skeleton
          sx={{
            margin: '1.5rem 0',
            borderRadius: '1rem',
          }}
          variant="rectangular"
          animation="wave"
          width={'70%'}
          height={60}
        />
        <Skeleton
          sx={{
            margin: '1.5rem 0',
            borderRadius: '1rem',
          }}
          variant="rectangular"
          animation="wave"
          width={'100%'}
          height={200}
        />
        <Skeleton
          sx={{
            margin: '1.5rem 0',
            borderRadius: '1rem',
          }}
          variant="rectangular"
          animation="wave"
          width={'100%'}
          height={200}
        />
        <Skeleton
          sx={{
            margin: '1.5rem 0',
            borderRadius: '1rem',
          }}
          variant="rectangular"
          animation="wave"
          width={'100%'}
          height={200}
        />
        <Skeleton
          sx={{
            margin: '1.5rem 0',
            borderRadius: '1rem',
          }}
          variant="rectangular"
          animation="wave"
          width={'100%'}
          height={200}
        />
      </>
    );
  };

  const renderLastUpdate = () => {
    if (!activeArticle || !activeArticle.sections.length) return null;
    let date = moment(activeArticle.sections[0].createdAt);
    activeArticle.sections.forEach((section) => {
      if (section.updatedAt) {
        const sectionDate = moment(section.updatedAt);
        if (sectionDate.isAfter(date)) {
          date = sectionDate;
        }
      }
    });
    return <p>Last Updated: {date.format('MMMM DD, YYYY')}</p>;
  };

  const getLinkedVignettes = async (id: string) => {
    const response = await fetchArticleVignettes(id);
    setLinkedVignettes(response);
  };

  const toggleVignetteSuspendStatus = async (
    isSuspended: boolean,
    id: string
  ) => {
    const response = await updateVignetteSuspendStatus(id, {
      isSuspended: isSuspended,
    });
    if (response) {
      getLinkedVignettes(articleId);
    }
  };

  const toggleVignetteVariantQAStatus = async (
    activeVignetteVariant: VignetteContent,
    isQAed: boolean,
    id: string
  ) => {
    const updatedVariant = {
      question: activeVignetteVariant.question,
      explanation: activeVignetteVariant.explanation,
      exhibit: activeVignetteVariant.exhibit
        ? activeVignetteVariant.exhibit
        : '',
      learningObjective: activeVignetteVariant.learningObjective,
      answerOptions: activeVignetteVariant.answerOptions,
      isQAed: isQAed,
    };
    await updateVignetteVariant(id, updatedVariant);
    getLinkedVignettes(articleId);
  };

  const toggleFlashcardSuspendStatus = async (
    isSuspended: boolean,
    id: string
  ) => {
    const response = await updateFlashcardSuspendStatus(id, {
      isSuspended: !isSuspended,
    });
    if (response) {
      getLinkedVignettes(articleId);
    }
  };

  const toggleFlashcardVariantQAStatus = async (
    activeFlashcardVariant: FlashcardContent,
    isQAed: boolean,
    variantId: string
  ) => {
    const updatedVariant = {
      front: activeFlashcardVariant.front,
      back: activeFlashcardVariant.back,
      explanation: activeFlashcardVariant.explanation,
      isQAed: isQAed,
    };
    await updateFlashcardVariant(variantId, updatedVariant);
    getLinkedVignettes(articleId);
  };

  return (
    <>
      <div className="article">
        <div className="article_body">
          <div className="article_body_center">
            <div className="article_body_center_card">
              {activeArticle && (
                <>
                  <div className="article_body_center_card_content">
                    <h1>{activeArticle.title}</h1>
                    {renderArticleSections(activeArticle.sections)}
                  </div>
                  <div className="article_body_center_card_footer">
                    {renderLastUpdate()}
                    {currentUser && (
                      <button
                        className="button button--secondary button--report"
                        onClick={() => setReportArticleModalIsOpen(true)}
                      >
                        <AlertTriangle />
                        Report Article
                      </button>
                    )}
                  </div>
                </>
              )}
              {(!activeArticle || articleLoading) && (
                <div
                  className="article_body_center_card_content"
                  style={{ paddingTop: '1.5rem' }}
                >
                  {renderLoadingFrames()}
                </div>
              )}
              {currentUser?.role === 'admin' && !currentUser?.demoMode && (
                <div className="article_body_center_card_editor">
                  <h2>QBank Questions</h2>
                  {linkedVignettes.map((vignette) => (
                    <div key={vignette.id} className="vignette">
                      <div className="admin-toolbar">
                        <div className="suspend-status m-b-1">
                          {vignette.isSuspended ? (
                            <div className="badge badge--error">Suspended</div>
                          ) : (
                            <div className="badge badge--success">Active</div>
                          )}
                        </div>
                        <button
                          className="button button--edit m-b-1"
                          style={{ marginLeft: '0.25rem' }}
                          onClick={() => {
                            toggleVignetteSuspendStatus(
                              !vignette.isSuspended,
                              vignette.id
                            );
                          }}
                        >
                          {vignette.isSuspended ? (
                            <ToggleLeft />
                          ) : (
                            <ToggleRight />
                          )}
                        </button>
                        <div className="spacer m-b-1">|</div>
                        <div className="qa-status m-b-1">
                          {vignette.contents[0].isQAed ? (
                            <div className="is-qaed">Is QAed</div>
                          ) : (
                            <div className="is-not-qaed">Is Not QAed</div>
                          )}
                        </div>
                        <button
                          className="button button--edit m-b-1"
                          style={{ marginLeft: '0.25rem' }}
                          onClick={() => {
                            toggleVignetteVariantQAStatus(
                              vignette.contents[0],
                              !vignette.contents[0].isQAed,
                              vignette.contents[0].id
                            );
                          }}
                        >
                          {vignette.contents[0].isQAed ? <X /> : <Check />}
                        </button>
                        <div className="spacer m-b-1">|</div>
                        <button
                          className="uuid-display m-t-0"
                          onClick={() => copyToClipboard(vignette.id)}
                        >
                          <Copy />
                          {vignette.id}
                        </button>
                        <button
                          className="button button--edit m-b-1"
                          onClick={() => {
                            setActiveVignette(vignette);
                            setActiveVignetteVariant(vignette.contents[0]);
                            setIsEditingVignette(true);
                          }}
                        >
                          <Edit3 />
                        </button>
                      </div>
                      {parse(vignette.contents[0].question)}
                      <div className="answers">
                        {vignette.contents[0].answerOptions.map((answer) => (
                          <div
                            key={vignette.id + '-' + answer.optionLetter}
                            className={
                              answer.optionLetter ===
                              vignette.contents[0].correctAnswer
                                ? 'correct'
                                : ''
                            }
                          >
                            {answer.optionLetter}. {parse(answer.optionText)}
                          </div>
                        ))}
                      </div>
                      <hr />
                      {vignette.flashcards && (
                        <div className="flashcards">
                          <Accordion>
                            <AccordionSummary>
                              <h3>
                                Flashcards
                                <ChevronDown />
                              </h3>
                            </AccordionSummary>
                            <AccordionDetails>
                              {vignette.flashcards.map((flashcard) => (
                                <div key={flashcard.id} className="card">
                                  <div className="admin-toolbar">
                                    <div className="suspend-status m-b-1">
                                      {flashcard.isSuspended ? (
                                        <div className="badge badge--error">
                                          Suspended
                                        </div>
                                      ) : (
                                        <div className="badge badge--success">
                                          Active
                                        </div>
                                      )}
                                    </div>
                                    <button
                                      className="button button--edit m-b-1"
                                      style={{ marginLeft: '0.25rem' }}
                                      onClick={() => {
                                        toggleFlashcardSuspendStatus(
                                          !!flashcard.isSuspended,
                                          flashcard.id
                                        );
                                      }}
                                    >
                                      {flashcard.isSuspended ? (
                                        <ToggleLeft />
                                      ) : (
                                        <ToggleRight />
                                      )}
                                    </button>
                                    <div className="spacer m-b-1">|</div>
                                    <div className="qa-status m-b-1">
                                      {flashcard.contents[0].isQAed ? (
                                        <div className="is-qaed">Is QAed</div>
                                      ) : (
                                        <div className="is-not-qaed">
                                          Is Not QAed
                                        </div>
                                      )}
                                    </div>
                                    <button
                                      className="button button--edit m-b-1"
                                      style={{ marginLeft: '0.25rem' }}
                                      onClick={() => {
                                        toggleFlashcardVariantQAStatus(
                                          flashcard.contents[0],
                                          !flashcard.contents[0]?.isQAed,
                                          // @ts-expect-error - To be fixed
                                          flashcard.contents[0]?.id
                                        );
                                      }}
                                    >
                                      {flashcard.contents[0].isQAed ? (
                                        <X />
                                      ) : (
                                        <Check />
                                      )}
                                    </button>
                                    <div className="spacer m-b-1">|</div>
                                    <button
                                      className="uuid-display m-t-0"
                                      onClick={() =>
                                        copyToClipboard(flashcard.id)
                                      }
                                    >
                                      <Copy />
                                      {flashcard.id}
                                    </button>
                                    <button
                                      className="button button--edit m-b-1"
                                      onClick={() => {
                                        setActiveFlashcard(flashcard);
                                        setActiveFlashcardVariant(
                                          flashcard.contents[0]
                                        );
                                        setIsEditingFlashcard(true);
                                      }}
                                    >
                                      <Edit3 />
                                    </button>
                                  </div>
                                  <div>{parse(flashcard.contents[0].back)}</div>
                                </div>
                              ))}
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      )}
                    </div>
                  ))}
                  {activeVignette && activeVignetteVariant && (
                    <EditVignette
                      isEditing={isEditingVignette}
                      setIsEditing={setIsEditingVignette}
                      activeVignette={activeVignette}
                      activeVignetteVariant={activeVignetteVariant}
                      setKeybindsDisabled={() => {}}
                    />
                  )}
                  {activeFlashcard && activeFlashcardVariant && (
                    <EditFlashcard
                      isEditing={isEditingFlashcard}
                      setIsEditing={setIsEditingFlashcard}
                      flashcardId={activeFlashcard.id}
                      activeFlashcardVariant={activeFlashcardVariant}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          {!isStudyPanel && (
            <div className="article_body_right">
              <JumpToMenu
                headings={headings}
                onClick={scrollToSection}
                loading={jumpLoading}
              />
            </div>
          )}
        </div>
        {activeArticle && (
          <ReportArticle
            activeArticle={activeArticle}
            reportArticleModalIsOpen={reportArticleModalIsOpen}
            setReportArticleModalIsOpen={setReportArticleModalIsOpen}
          />
        )}
      </div>
      {currentUser?.role === 'admin' && (
        <Modal
          open={isEditing}
          onClose={() => {
            setIsEditing(false);
            setCurrentArticleSectionText('');
            setCurrentArticleSectionId('');
          }}
        >
          <div className="modal modal--glass modal--edit-article-section">
            <div className="modal_header">
              <h2>Edit Article Section</h2>
            </div>
            <div className="modal_content">
              <form>
                <div>
                  <p className="m-y-0">Section ID: {currentArticleSectionId}</p>
                </div>
                <div className="row">
                  <div className="editor">
                    <label htmlFor="editor--flashcard-front">Content</label>
                    <textarea
                      id="editor--flashcard-front"
                      value={currentArticleSectionText}
                      onChange={handleArticleSectionTextChange}
                    ></textarea>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal_actions">
              <button
                className="button button--glass button--back"
                onClick={() => {
                  setIsEditing(false);
                  hapticsImpactLight();
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="button button--glass button--submit"
                onClick={() => {
                  hapticsImpactLight();
                  submitArticleSectionUpdate();
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ArticlePage;
