import { BlockType, StudyPhase } from './Study';

export type ResponseOption = 'two' | 'four';

export enum UserRole {
  default = 'default',
  admin = 'admin',
}

export interface UserExam {
  id: string;
  name: string;
  examDate: Date;
  primary: boolean;
  selectedFlashcardCount: number;
  selectedVignetteCount: number;
  isDefaultDate: boolean;
  isUsingFlashcards: boolean;
  isUsingQbank: boolean;
  hasCompletedRealExam: boolean;
  realExamScore: number | null;
  ignoredExamDate: boolean;
}

export interface Exam {
  id: string;
  name: string;
  flashcardCount: number;
  vignetteCount: number;
}

export interface User {
  id: string;
  name?: string | null;
  email: string | null;
  createdAt: Date;
  oauth?: boolean | null;
  oauthStrategy?: string | null;
  role: UserRole;
  timezone: string;
  isOnboarded: boolean;
  onboardingTips: { [key: string]: boolean };
  darkMode: boolean;
  demoMode: boolean;
  hasWatchedWelcomeVideo: boolean;
  responseOptions: ResponseOption;
  allowedBetaUser: boolean;
  exams: UserExam[];
  preferredStartPhase: StudyPhase;
  preferredBlockType: BlockType;
}

export interface UpdateUserInfoRequest {
  name?: string | null;
  email?: string | null;
  timezone?: string;
  isOnboarded?: boolean;
  darkMode?: boolean;
  demoMode?: boolean;
  hasWatchedWelcomeVideo?: boolean;
  responseOptions?: ResponseOption;
  onboardingTips?: { [key: string]: boolean };
  preferredStartPhase?: StudyPhase;
  preferredBlockType?: string;
}
