import React, { useEffect, useRef, useState } from 'react';
import Header from '../../components/Global/Header';
import Flashcards from '../../components/Study/Flashcards';
import Exam from '../../components/Study/Exam';
import { PanelType, StudyPhase } from '../../types/Study';
import { fetchVignettesForBlock } from '../../services/study';
import { HeaderType } from '../../types/Header';
import SessionReview from '../../components/Study/SessionReview';
import { RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { setTodaySession } from '../../slices/todaySession/todaySessionSlice';
import { updateStudySession } from '../../services/studySession';
import { setVignettes } from '../../slices/vignettes/vignettesSlice';
import { useSnackbar } from 'notistack';
import { useRefreshStudySessionAndContent } from '../../utils/refreshStudySession';
import { posthog } from 'posthog-js';
import {
  fadeInFlashLoader,
  fadeInOutFlashLoader,
  fadeOutFlashLoader,
} from '../../utils/flashLoader';

const Study: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const todaySession = useSelector((state: RootState) => state.todaySession);
  const blocks = useSelector((state: RootState) => state.blocks);
  const newFlashcards = useSelector((state: RootState) => state.flashcards.new);
  const dueFlashcards = useSelector((state: RootState) => state.flashcards.due);
  const activeBlockId = useSelector(
    (state: RootState) => state.todaySession.activeBlockId
  );
  const vignettes = useSelector(
    (state: RootState) => state.vignettes.vignettes
  );

  const [fadeInSessionReview, setFadeInSessionReview] = useState(false);
  const [isQbankStart, setIsQbankStart] = useState(true);
  const [pageSwitch, setPageSwitch] = useState(true);
  const [panelType, setPanelType] = useState<PanelType>(PanelType.library);

  const flashLoaderRef = useRef<HTMLDivElement>(null);

  const {
    isLoadingStudySessionAndContent,
    isLoadedStudySessionAndContent,
    setIsLoadedStudySessionAndContent,
    refreshStudySessionAndContent,
  } = useRefreshStudySessionAndContent();

  const dispatch = useDispatch();

  const renderToast = (message: string) => {
    enqueueSnackbar(message, { autoHideDuration: 3000 });
  };

  // On Mount, init study session
  useEffect(() => {
    initSession();

    document.body.classList.add('study-theme');

    return () => {
      document.body.classList.remove('study-theme');
      if (todaySession.phase === 'qbankReview' && !todaySession.isReview) {
        changePhase(StudyPhase.sessionReview);
      }
    };
  }, []);

  const initSession = async () => {
    console.log('XXXXX Initiating Study Session...');
    console.log('XXXXX Checking if todaySession and content is loaded...');
    if (!isValidSessionAndContentLoaded() && !isLoadingStudySessionAndContent) {
      console.log(
        'XXXXX In Study component and todaySession or content is not loaded. Refreshing session & content...'
      );
      await refreshStudySessionAndContent();
      console.log('XXXXX Study Session and Content Finished Loading.');
      if (todaySession.isComplete) {
        changePhase(StudyPhase.sessionReview);
      }
    } else {
      // Set isLoadedStudySessionAndContent to true if todaySession and content is already loaded
      console.log('XXXXX Today Session and Content is already loaded.');
      setIsLoadedStudySessionAndContent(true);
    }
    if (todaySession && todaySession.phase === 'sessionReview') {
      setFadeInSessionReview(true);
    }
    if (todaySession) {
      console.log('Session Phase: ' + todaySession.phase);

      // set today session isStarted to true
      if (todaySession.id && !todaySession.isStarted) {
        const updatedInfo = { ...todaySession, isStarted: true };
        const response = await updateStudySession(todaySession.id, updatedInfo);
        dispatch(setTodaySession(response));
      }
    }
  };

  // Check if flashcards, blocks, and vignettes already loaded
  const isValidSessionAndContentLoaded = () => {
    // Check if todaySession is present and has today's date
    if (!todaySession) return false;
    // Skip date check if a review session is loaded
    if (todaySession.isReview) return true;

    const today = new Date();
    const sessionDate = new Date(todaySession.date);
    const todaySessionIsLoaded =
      today.toDateString() === sessionDate.toDateString();

    if (!todaySessionIsLoaded) {
      console.log(
        'Today Session is expired. Refreshing today session and content...'
      );
      return false;
    }

    // Check if flashcards are loaded
    if (
      todaySession.completedFlashcardCount < todaySession.flashcardCount &&
      newFlashcards?.length === 0 &&
      dueFlashcards?.length === 0
    ) {
      console.log(
        'Flashcards not loaded. Refreshing today session and content...'
      );
      return false;
    }

    // Check if blocks are loaded
    if (blocks.length === 0) {
      console.log('Blocks not loaded. Refreshing today session and content...');
      return false;
    }

    // Check if vignettes are loaded
    if (!vignettes || vignettes.length === 0) {
      console.log(
        'Vignettes not loaded. Refreshing today session and content...'
      );
      return false;
    }

    return true;
  };

  // Wait for all content to load before determining next phase
  useEffect(() => {
    if (isLoadedStudySessionAndContent && !isLoadingStudySessionAndContent) {
      console.log('Both Flashcards and QBank Loaded');
      determineNextPhase();
    }
  }, [isLoadedStudySessionAndContent, isLoadingStudySessionAndContent]);

  // Listen to Flashcard Responses, Change Phase if Necessary
  useEffect(() => {
    if (isLoadedStudySessionAndContent && !isLoadingStudySessionAndContent) {
      if (todaySession?.phase === 'flashcard') {
        console.log(
          'New: ' + newFlashcards.length + ' | Due: ' + dueFlashcards.length
        );
        determineNextPhase();
      }
    }
  }, [
    newFlashcards,
    dueFlashcards,
    isLoadedStudySessionAndContent,
    isLoadingStudySessionAndContent,
  ]);

  const changePhase = async (phase: StudyPhase, data?: object) => {
    if (!todaySession) return;
    setPageSwitch(false);

    // Override if trying to change to a phase that is not possible
    phase = safetyCheckPhaseSelection(phase);

    if (phase !== StudyPhase.qbank) {
      document.body.classList.remove('in-exam');
    } else {
      setIsQbankStart(true);
    }
    const updatedInfo = { ...todaySession, phase: phase, ...data };
    const response = await updateStudySession(todaySession.id, updatedInfo);
    dispatch(setTodaySession(response));
    if (phase === StudyPhase.sessionReview) {
      setFadeInSessionReview(true);
    }
    setTimeout(() => {
      setPageSwitch(true);
      fadeOutFlashLoader(flashLoaderRef);
    }, 250);
  };

  const safetyCheckPhaseSelection = (phase: StudyPhase) => {
    const flashcardsRemaining =
      todaySession?.flashcardCount > todaySession?.completedFlashcardCount;
    const unsubmittedBlocksRemaining = blocks.some(
      (block) => !block.isSubmitted
    );
    const blocksPresent = blocks.length > 0;
    if (phase === StudyPhase.flashcard && !flashcardsRemaining) {
      if (unsubmittedBlocksRemaining) {
        return StudyPhase.qbank;
      } else {
        return StudyPhase.sessionReview;
      }
    } else if (phase === StudyPhase.qbank && !blocksPresent) {
      if (flashcardsRemaining) {
        return StudyPhase.flashcard;
      } else {
        return StudyPhase.sessionReview;
      }
    } else if (phase === StudyPhase.sessionReview) {
      if (flashcardsRemaining && !unsubmittedBlocksRemaining) {
        return StudyPhase.flashcard;
      } else if (!flashcardsRemaining && unsubmittedBlocksRemaining) {
        return StudyPhase.qbank;
      } else if (flashcardsRemaining && unsubmittedBlocksRemaining) {
        return StudyPhase.qbank;
      } else {
        return phase;
      }
    } else {
      return phase;
    }
  };

  const determineNextPhase = async () => {
    if (todaySession?.phase === StudyPhase.flashcard) {
      console.log('Phase is Flashcard');
      if (
        todaySession.completedFlashcardCount === todaySession.flashcardCount
      ) {
        console.log('No Flashcards Remaining, Checking QBank Blocks');
        let allBlocksSubmitted = true;
        blocks.forEach((block) => {
          if (!block.isSubmitted) {
            allBlocksSubmitted = false;
          }
        });
        if (allBlocksSubmitted) {
          console.log('All Blocks Submitted, Changing Phase to Session Review');
          changePhase(StudyPhase.sessionReview, { isComplete: true });
          posthog?.capture('study_session_complete');
        } else {
          console.log('Not All Blocks Submitted, Changing Phase to QBank');
          changePhase(StudyPhase.qbank);
        }
      } else {
        console.log('Remaining Flashcards, Staying in Flashcard Phase');
      }
    }
  };

  const findNextBlock = () => {
    return blocks
      .filter((block) => !block.isSubmitted)
      .sort((a, b) => (a.ord ?? 0) - (b.ord ?? 0))[0]?.id;
  };

  const determineCurrentBlockNumber = () => {
    const activeBlock = blocks.find((block) => block.id === activeBlockId);
    const submittedBlocks = blocks.filter((block) => block.isSubmitted).length;
    if (activeBlock && activeBlock.isSubmitted) {
      const submittedBlocks = blocks.filter((block) => block.isSubmitted);
      return submittedBlocks.length;
    }
    return submittedBlocks + 1;
  };

  const nextBlock = async () => {
    if (!todaySession) return;
    console.log('Determining Next Block');
    const nextBlockId = findNextBlock();
    if (!nextBlockId) {
      console.log('No More Blocks');
      if (
        todaySession.completedFlashcardCount === todaySession.flashcardCount
      ) {
        console.log('No More Flashcards, Switching to Session Review');
        changePhase(StudyPhase.sessionReview, { isComplete: true });
        posthog?.capture('study_session_complete');
        fadeInOutFlashLoader(flashLoaderRef);
      } else {
        console.log('Remaining Flashcards, Switching to Flashcard Phase');
        changePhase(StudyPhase.flashcard);
      }
    } else {
      console.log('Next Block Found, Switching to Next Block');
      const updatedInfo = { ...todaySession, activeBlockId: nextBlockId };
      const response = await updateStudySession(todaySession.id, updatedInfo);
      dispatch(setTodaySession(response));
      const vignettes = await fetchVignettesForBlock(nextBlockId);
      dispatch(setVignettes(vignettes.vignettes));
      setIsQbankStart(true);
      fadeOutFlashLoader(flashLoaderRef);
    }
  };

  const reviewQbankBlock = async (blockId: string) => {
    const updatedInfo = {
      ...todaySession,
      phase: StudyPhase.qbankReview,
      activeBlockId: blockId,
    };
    console.log('Reviewing QBank Block');
    const response = await updateStudySession(todaySession.id, updatedInfo);
    dispatch(setTodaySession(response));
    const vignettes = await fetchVignettesForBlock(blockId);
    dispatch(setVignettes(vignettes.vignettes));
  };

  return (
    <>
      <div
        className={`study ${isLoadedStudySessionAndContent && pageSwitch ? 'is-loaded' : ''} ${todaySession.phase === StudyPhase.qbankReview ? 'is-review' : ''} ${todaySession.phase === StudyPhase.flashcard ? 'phase--flashcard' : ''} ${todaySession.phase === StudyPhase.qbank && isQbankStart ? 'phase--qbank-start' : ''}`}
      >
        <div className="study_main">
          <Header
            type={HeaderType.study}
            changePhase={changePhase}
            isDiagnostic={false}
          />
          <div
            className="study_main_body"
            style={
              todaySession.phase === StudyPhase.qbank ||
              todaySession.phase === StudyPhase.sessionReview
                ? { overflow: 'hidden' }
                : {}
            }
          >
            {todaySession.phase === 'flashcard' && (
              <Flashcards
                isDiagnostic={false}
                renderToast={renderToast}
                panelType={panelType}
                setPanelType={setPanelType}
              />
            )}
            {(todaySession.phase === 'qbank' ||
              todaySession.phase === 'qbankReview') && (
              <Exam
                activeBlockId={activeBlockId}
                currentBlockNumber={determineCurrentBlockNumber()}
                changePhase={changePhase}
                nextBlock={nextBlock}
                vignettes={vignettes}
                isStart={isQbankStart}
                setIsStart={setIsQbankStart}
                isDiagnostic={false}
                fadeInOutFlashLoader={() =>
                  fadeInOutFlashLoader(flashLoaderRef)
                }
                fadeInFlashLoader={() => fadeInFlashLoader(flashLoaderRef)}
                fadeOutFlashLoader={() => fadeOutFlashLoader(flashLoaderRef)}
                reviewQbankBlock={reviewQbankBlock}
                panelType={panelType}
                setPanelType={setPanelType}
              />
            )}
            {todaySession.phase === 'sessionReview' && (
              <SessionReview
                todaySession={todaySession}
                reviewQbankBlock={reviewQbankBlock}
                revealReview={fadeInSessionReview}
                fadeInOutFlashLoader={() =>
                  fadeInOutFlashLoader(flashLoaderRef)
                }
                changePhase={changePhase}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className="flash-loader"
        ref={flashLoaderRef}
        style={
          currentUser?.darkMode
            ? {
                background:
                  'linear-gradient(67.73deg, #1b2934 5.33%, #05090e 117.24%)',
              }
            : {}
        }
      ></div>
    </>
  );
};

export default Study;
