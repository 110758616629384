import { Block, BlockType } from '../../../../types/Study';
import { convertSecondsToDisplayTime } from '../../../../utils/convertSecondsToDisplayTime';
import {
  hapticsImpactLight,
  hapticsImpactMedium,
} from '../../../../utils/haptics';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { ArrowRight, BookOpen, Clock, Info } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import IntercomButton from '../../../Global/IntercomButton';
import { UserExam } from '../../../../types/User';
import PastQBankBlock from '../../../Global/PastQBankBlock';
import { useState } from 'react';
import { updateCurrentUserInfo } from '../../../../services/auth';
import { setUserInfo } from '../../../../slices/auth/authSlice';

interface StartScreenProps {
  activeBlock: Block | null;
  currentBlockNumber: number;
  isDiagnostic: boolean;
  timeRemaining: number;
  startBlock: (type: BlockType) => void;
  resumeBlock: () => void;
  primaryExam: UserExam | undefined;
  fadeInFlashLoader: () => void;
  reviewQbankBlock?: (blockId: string) => void;
}

const StartScreen: React.FC<StartScreenProps> = ({
  activeBlock,
  currentBlockNumber,
  isDiagnostic,
  timeRemaining,
  startBlock,
  resumeBlock,
  primaryExam,
  fadeInFlashLoader,
  reviewQbankBlock,
}) => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const blockCount = useSelector((state: RootState) => state.blocks.length);
  const previousBlocks = useSelector((state: RootState) =>
    state.blocks.filter((b) => b.isSubmitted)
  );
  const [isChangingBlockType, setIsChangingBlockType] = useState(false);

  const dispatch = useDispatch();

  const renderPreviousBlocks = () => {
    if (previousBlocks.length === 0) return;
    return (
      <>
        <h2>Previous Blocks</h2>
        {[...previousBlocks]
          .sort((a, b) => (a.ord ?? 0) - (b.ord ?? 0))
          .map((block, i) => {
            return (
              <PastQBankBlock
                key={block.id}
                title={`Block ${i + 1}`}
                blockType={`${block.type.charAt(0).toUpperCase() + block.type.slice(1)}`}
                questionCount={block.vignetteCount}
                correctCount={block.correctCount}
                incorrectCount={block.incorrectCount}
                omittedCount={block.omittedCount}
                reviewBlockFunction={() => {
                  fadeInFlashLoader();
                  console.log('Loading a Past Today Block for Review');
                  setTimeout(() => {
                    if (reviewQbankBlock) reviewQbankBlock(block.id);
                  }, 250);
                }}
              />
            );
          })}
      </>
    );
  };

  const updateUserBlockPreference = async (type: BlockType) => {
    const updateUser = await updateCurrentUserInfo({
      preferredBlockType: type,
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  return (
    <div
      className={`exam-start ${isDiagnostic ? 'is-diagnostic' : ''} ${currentBlockNumber === blockCount && activeBlock?.isSubmitted ? 'all-submitted' : ''}`}
    >
      <div className="exam-start_wrapper">
        <div className="exam-start_wrapper_header">
          <div className="title">
            {primaryExam && <div>{primaryExam.name}</div>}
            <h1>Question Bank</h1>
          </div>
        </div>
        <div className="exam-start_wrapper_main">
          <div className="exam-start_wrapper_main_start">
            <h2>
              Block {currentBlockNumber} <span>of {blockCount}</span>
            </h2>
            <div
              className={`exam-start_wrapper_main_start_container ${isChangingBlockType ? 'two-col' : ''}`}
            >
              {(!activeBlock?.isStarted ||
                activeBlock.type === BlockType.timed) &&
                (currentUser?.preferredBlockType === BlockType.timed ||
                  isChangingBlockType) && (
                  <div className="exam-start_wrapper_main_start_container_timed">
                    <button
                      className="panel"
                      onClick={() => {
                        fadeInFlashLoader();
                        hapticsImpactMedium();
                        localStorage.removeItem('ora-today-block-id');
                        setTimeout(() => {
                          if (!activeBlock?.isStarted) {
                            startBlock(BlockType.timed);
                          } else {
                            resumeBlock();
                          }
                        }, 250);
                      }}
                    >
                      <div className="panel_main">
                        <Clock />
                      </div>
                      <div className="panel_footer">
                        <h3>
                          Timed Mode
                          <Tooltip
                            title={
                              'In Timed Mode, you will have a set amount of time to complete all of the block questions. Review will be available after the block is completed.'
                            }
                            enterTouchDelay={0}
                          >
                            <Info />
                          </Tooltip>
                        </h3>
                        <p>
                          {activeBlock?.vignetteCount} Questions |{' '}
                          {convertSecondsToDisplayTime(timeRemaining)}
                        </p>
                        <div className="button button--glass button--mobile">
                          {!activeBlock?.isStarted
                            ? 'Start Timed Block'
                            : 'Resume Block'}
                        </div>
                      </div>
                    </button>
                  </div>
                )}
              {(!activeBlock?.isStarted ||
                activeBlock.type === BlockType.tutor) &&
                (currentUser?.preferredBlockType === BlockType.tutor ||
                  isChangingBlockType) && (
                  <div className="exam-start_wrapper_main_start_container_tutor">
                    <button
                      className="panel"
                      onClick={() => {
                        fadeInFlashLoader();
                        hapticsImpactMedium();
                        localStorage.removeItem('ora-today-block-id');
                        updateUserBlockPreference(BlockType.tutor);
                        setTimeout(() => {
                          startBlock(BlockType.tutor);
                        }, 250);
                      }}
                    >
                      <div className="panel_main">
                        <BookOpen />
                      </div>
                      <div className="panel_footer">
                        <h3>
                          Untimed Mode
                          <Tooltip
                            title={
                              'In Untimed Mode, you will see questions one at a time with review following each question.'
                            }
                            enterTouchDelay={0}
                          >
                            <Info />
                          </Tooltip>
                        </h3>
                        <p>{activeBlock?.vignetteCount} Questions | Untimed</p>
                        <div className="button button--glass button--mobile">
                          {!activeBlock?.isStarted
                            ? 'Start Untimed Block'
                            : 'Resume Block'}
                        </div>
                      </div>
                    </button>
                  </div>
                )}
              {!isChangingBlockType && !activeBlock?.isStarted && (
                <button
                  className="button button--link"
                  onClick={() => {
                    setIsChangingBlockType(!isChangingBlockType);
                    hapticsImpactLight();
                  }}
                >
                  Change Block Type
                  <ArrowRight />
                </button>
              )}
            </div>
          </div>
          {previousBlocks.length > 0 && (
            <div className="exam-start_wrapper_main_previous">
              {renderPreviousBlocks()}
            </div>
          )}
        </div>
        <IntercomButton />
      </div>
    </div>
  );
};

export default StartScreen;
