'use client';

import { X } from 'react-feather';

interface MSMobileMenuProps {}

const MSMobileMenu: React.FC<MSMobileMenuProps> = () => {
  return (
    <div className="ms-mobile-menu">
      <div className="ms-mobile-menu_drawer">
        <button
          className="ms-mobile-menu_drawer_close"
          onClick={() => {
            const mobileMenu = document.querySelector('.ms-mobile-menu_drawer');
            if (mobileMenu) {
              mobileMenu.classList.remove('is-open');
            }
          }}
        >
          <X />
        </button>
        <ul>
          <li>
            <a href="https://app.oraai.com/">Sign In</a>
          </li>
          <li>
            <a href="https://app.oraai.com/auth?signup=true">Sign Up</a>
          </li>
          <hr />
          <li>
            <a href="/#how-it-works">How It Works</a>
          </li>
          <li>
            <a href="https://app.oraai.com/library">Library</a>
          </li>
          <li>
            <a href="/team">Team</a>
          </li>
          <li>
            <button
              onClick={() => {
                window.Intercom('show');
              }}
            >
              Contact
            </button>
          </li>
        </ul>
      </div>
      <div
        className="mobile-menu_overlay"
        onClick={() => {
          const mobileMenu = document.querySelector('.mobile-menu_drawer');
          if (mobileMenu) {
            mobileMenu.classList.remove('is-open');
          }
        }}
      ></div>
    </div>
  );
};

export default MSMobileMenu;
