import axiosInstance from './axiosCreator';

interface ReportArticleRequest {
  articleTitle: string;
  articleId: string;
  email: string;
  reason: string;
}

// Fetch library topic tree
export const fetchLibraryTopicTree = async () => {
  try {
    const response = await axiosInstance.get('/library/topics');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Fetch article by slug or id
export const fetchArticle = async (slug: string) => {
  try {
    const response = await axiosInstance.get('/library/' + slug);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Fetch article by topicId
export const fetchArticleByTopicId = async (topicId: string) => {
  try {
    const response = await axiosInstance.get('/library/topic/' + topicId);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Report article
export const reportArticle = async (reportInfo: ReportArticleRequest) => {
  try {
    const response = await axiosInstance.post('/library/report', reportInfo);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Update flashcard variant
export const updateArticleSection = async (
  sectionId: string,
  data: {
    text: string;
    updatedAt: string;
  }
) => {
  try {
    const response = await axiosInstance.put(
      `/library/section/${sectionId}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Get Vignettes for Article
export const fetchArticleVignettes = async (id: string) => {
  try {
    const response = await axiosInstance.get('/article/' + id + '/vignettes');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
