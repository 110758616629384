import React, { useRef, useEffect, useState } from 'react';
import VideoSource from '../../../assets/videos/welcomev2.mp4';
import VideoThumbnail from '../../../assets/images/welcome-thumbnail.jpg';
import { PlayCircle, X } from 'react-feather';
import Modal from '@mui/material/Modal';
import { hapticsImpactLight } from '../../../utils/haptics';
import { updateCurrentUserInfo } from '../../../services/auth';
import { setUserInfo } from '../../../slices/auth/authSlice';
import { useDispatch } from 'react-redux';

const VideoPlayer: React.FC<{
  setIsWatched: (watched: boolean) => void;
  setHasWatchedHalf?: (watched: boolean) => void;
}> = ({ setIsWatched, setHasWatchedHalf }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const watchedSegmentsRef = useRef<boolean[]>([]);
  const totalWatchedRef = useRef<number>(0);
  const hasReachedHalfRef = useRef<boolean>(false);
  const videoDurationRef = useRef<number>(0);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleLoadedMetadata = () => {
      const duration = Math.floor(video.duration);
      videoDurationRef.current = duration;
      watchedSegmentsRef.current = new Array(duration).fill(false);
      totalWatchedRef.current = 0;
    };

    const handleTimeUpdate = () => {
      const currentTime = Math.floor(video.currentTime);
      if (!watchedSegmentsRef.current[currentTime]) {
        watchedSegmentsRef.current[currentTime] = true;
        totalWatchedRef.current += 1;

        // Update hasWatched as soon as 50% is reached
        if (
          !hasReachedHalfRef.current &&
          totalWatchedRef.current >= watchedSegmentsRef.current.length * 0.5
        ) {
          hasReachedHalfRef.current = true;
          if (setHasWatchedHalf) {
            setHasWatchedHalf(true);
          }
        }
      }
    };

    const handleEnded = () => {
      if (totalWatchedRef.current >= watchedSegmentsRef.current.length * 0.5) {
        setIsWatched(true);
      } else {
        setIsWatched(false);
      }
    };

    // Add event listeners
    video.addEventListener('loadedmetadata', handleLoadedMetadata);
    video.addEventListener('timeupdate', handleTimeUpdate);
    video.addEventListener('ended', handleEnded);

    // Cleanup event listeners on component unmount
    return () => {
      video.removeEventListener('loadedmetadata', handleLoadedMetadata);
      video.removeEventListener('timeupdate', handleTimeUpdate);
      video.removeEventListener('ended', handleEnded);
    };
  }, [setIsWatched, setHasWatchedHalf]);

  return (
    <video
      id="welcome-video"
      ref={videoRef}
      width="100%"
      height="auto"
      style={{ maxWidth: '100%', borderRadius: '.5rem' }}
      controls
      poster={VideoThumbnail}
    >
      <source src={VideoSource} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

interface WelcomeVideoProps {
  isReminder: boolean;
  setIsWatched: (watched: boolean) => void;
  setHasWatchedHalf?: (watched: boolean) => void;
}

const WelcomeVideo: React.FC<WelcomeVideoProps> = ({
  isReminder,
  setIsWatched,
  setHasWatchedHalf,
}) => {
  const [videoModalIsOpen, setVideoModalIsOpen] = useState(false);

  const dispatch = useDispatch();

  const handleWatchedVideo = async () => {
    const updateUser = await updateCurrentUserInfo({
      hasWatchedWelcomeVideo: true,
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  return (
    <div className="welcome-video">
      {!isReminder && setHasWatchedHalf && (
        <VideoPlayer
          setIsWatched={setIsWatched}
          setHasWatchedHalf={setHasWatchedHalf}
        />
      )}
      {isReminder && (
        <>
          <div className="welcome-video_teaser">
            <button
              className="button button--watch-welcome-video"
              onClick={() => {
                setVideoModalIsOpen(true);
                hapticsImpactLight();
              }}
            >
              <div className="button--watch-welcome-video_top">
                <span className="circle-pulse">
                  <PlayCircle className="circle-pulse" />
                </span>
                <span>Learn how Ora works</span>
              </div>
              <div className="button--watch-welcome-video_bottom">
                <span className="circle-pulse">
                  <PlayCircle className="circle-pulse" />
                </span>
                <span>1min 24s</span>
              </div>
            </button>
            <button
              className="button button--close button--icon-only"
              onClick={handleWatchedVideo}
            >
              <X />
            </button>
          </div>
          <Modal
            open={videoModalIsOpen}
            onClose={() => setVideoModalIsOpen(false)}
          >
            <div className="modal modal--glass modal--welcome-video">
              <div className="modal_content">
                <div className="video-wrapper">
                  <VideoPlayer setIsWatched={setIsWatched} />
                </div>
              </div>
              <div className="modal_actions">
                <button
                  className="button button--glass button--close"
                  style={{ marginLeft: 'auto' }}
                  onClick={() => {
                    setVideoModalIsOpen(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default WelcomeVideo;
