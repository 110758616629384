import { useEffect } from 'react';
import { router } from './routes/Routes';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { darkTheme, theme } from './theme/theme';
import { RootState } from './store/store';
import { useSelector } from 'react-redux';
import { Capacitor } from '@capacitor/core';
import { posthog } from 'posthog-js';

function App() {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);

  useEffect(() => {
    window.Intercom('shutdown');
    if (currentUser && currentUser.email) {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'x6oeppkc',
        source: 'ora',
        name: currentUser.name || '',
        email: currentUser.email,
        user_id: currentUser.id,
        created_at: currentUser.createdAt,
      });

      // Identify user in PostHog
      posthog.identify(currentUser.id, {
        email: currentUser.email,
        name: currentUser.name,
        created_at: currentUser.createdAt,
        role: currentUser.role,
      });

      // Start Recording user session for Authenticated Users
      if (
        !currentUser.email.includes('jctest') &&
        !currentUser.email.includes('kbtest') &&
        !currentUser.email.includes('rptest') &&
        !currentUser.email.includes('jacob.caccamo') &&
        !currentUser.email.includes('ryanllewellynphelps') &&
        !currentUser.email.includes('ryanrlphelps') &&
        !currentUser.email.includes('losaltoslandscaping.com') &&
        !currentUser.email.includes('kaycbas') &&
        !currentUser.email.includes('kevin@synaptiq.co') &&
        !currentUser.email.includes('jacob@synaptiq.co') &&
        !currentUser.email.includes('ryan@synaptiq.co') &&
        !currentUser.email.includes('kevin@oraai.com') &&
        !currentUser.email.includes('jacob@oraai.com') &&
        !currentUser.email.includes('ryan@oraai.com') &&
        !currentUser.email.includes('vkbzs8xkvd@privaterelay.appleid.com') // Jake's Apple Account
      ) {
        // posthog.startSessionRecording();
      }
    } else {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'x6oeppkc',
        source: 'ora',
      });
    }

    // Update body class based on user's dark mode preference
    if (currentUser && currentUser.darkMode) {
      document.body.classList.add('theme-dark');
    } else {
      document.body.classList.remove('theme-dark');
    }

    if (currentUser && currentUser.demoMode) {
      document.body.classList.add('DEMO-MODE');
    } else {
      document.body.classList.remove('DEMO-MODE');
    }
  }, [currentUser]);

  // Add classes based on platform
  if (Capacitor.getPlatform() === 'ios') {
    document.body.classList.add('CAPACITOR-IOS');
  }
  if (Capacitor.getPlatform() === 'android') {
    document.body.classList.add('CAPACITOR-ANDROID');
  }
  if (Capacitor.isNativePlatform()) {
    document.body.classList.add('IS-CAPACITOR');
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={currentUser?.darkMode ? darkTheme : theme}>
          <RouterProvider router={router} />
        </ThemeProvider>
      </LocalizationProvider>
    </>
  );
}

export default App;
