import SessionOverview from './SessionOverview';
import Ring from '../../../assets/images/ring.svg';
import { hapticsImpactMedium } from '../../../utils/haptics';
import { StartType } from '../../../types/Study';
import SnoozeSession from '../SnoozeSession';

interface StartProps {
  startTransition?: () => void;
  isStarted: boolean;
  type: StartType;
  action?: () => void;
  isSwitchingExam?: boolean;
  isLoadedStudySessionAndContent: boolean;
  isLoadingStudySessionAndContent: boolean;
}

const Start: React.FC<StartProps> = ({
  startTransition,
  isStarted,
  type,
  action,
  isSwitchingExam,
  isLoadedStudySessionAndContent,
  isLoadingStudySessionAndContent,
}) => {
  return (
    <div style={{ position: 'relative' }}>
      <div className="start">
        <div className="start_ring">
          <button
            onClick={() => {
              if (type === StartType.Home) {
                hapticsImpactMedium();
                if (startTransition) {
                  startTransition();
                }
              } else {
                if (action) {
                  action();
                }
              }
            }}
            disabled={
              !isLoadedStudySessionAndContent ||
              isLoadingStudySessionAndContent ||
              isSwitchingExam
            }
            className="start_ring_inner"
          >
            <h1 className="start_ring_inner_content">
              <span>{isStarted ? 'Resume' : 'Start'}</span>
            </h1>
            <div className="start_ring_inner_wrapper">
              <img src={Ring} alt="" />
            </div>
          </button>
          <SnoozeSession />
        </div>
        {type === StartType.Home && (
          <SessionOverview isSwitchingExam={isSwitchingExam} />
        )}
      </div>
    </div>
  );
};

export default Start;
