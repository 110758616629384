import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';
import Page from '../../components/Page';
import ArticleBrowser from '../../components/Library/ArticleBrowser';
import ArticlePage from '../../components/Library/ArticlePage';
import { Menu, X } from 'react-feather';
import { hapticsImpactLight } from '../../utils/haptics';
import MSHeader from '../../components/NonAuth/MSHeader';
import MSFooter from '../../components/NonAuth/MSFooter';
import { useUpdateTheme } from '../../hooks/useUpdateTheme';

interface LibraryProps {}

const Library: React.FC<LibraryProps> = () => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);

  const [activeArticleId, setActiveArticleId] = useState<string | null>(null);
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);

  const { articleId } = useParams<{ articleId: string }>();
  const { updateTheme } = useUpdateTheme();

  useEffect(() => {
    const resetLibrayView = () => {
      setActiveArticleId(null);
      const contentWindow = document.querySelector('.page_content');
      if (contentWindow) {
        contentWindow.scrollTo(0, 0);
      }
    };
    const libraryTab = document.getElementById('library-tab');
    if (libraryTab) {
      libraryTab.addEventListener('click', resetLibrayView);
    }
    return () => {
      if (libraryTab) {
        libraryTab.removeEventListener('click', resetLibrayView);
      }
    };
  }, []);

  // Load Article by URL
  useEffect(() => {
    if (articleId) {
      setActiveArticleId(articleId);
      const contentWindow = document.querySelector('.page_content');
      if (contentWindow) {
        contentWindow.scrollTo(0, 0);
      }
      window.scrollTo(0, 0);
    }
  }, [articleId]);

  useHotkeys(
    't',
    () => {
      if (currentUser) {
        updateTheme(!currentUser?.darkMode);
      }
    },
    { keyup: true }
  );

  const renderLibrary = () => {
    return (
      <div
        className={`library${activeArticleId ? ' is-article-page' : ' is-landing-page'}${!currentUser ? ' is-non-auth' : ''}`}
      >
        <div className="library_header">
          <div className="library_header_content">
            {activeArticleId && (
              <button
                className="button button--icon-only"
                onClick={() => {
                  setMobileMenuIsOpen(true);
                  hapticsImpactLight();
                }}
              >
                <Menu />
              </button>
            )}
            <h1>Library</h1>
          </div>
        </div>
        <div className="library_body">
          <div
            className={`library_body_browser ${mobileMenuIsOpen ? 'is-open' : ''}`}
          >
            <ArticleBrowser
              activeArticleId={activeArticleId}
              setActiveArticleId={setActiveArticleId}
              setMobileMenuIsOpen={setMobileMenuIsOpen}
            />
            <button
              className="button button--close"
              onClick={() => {
                hapticsImpactLight();
                setMobileMenuIsOpen(false);
              }}
            >
              <X />
            </button>
          </div>
          {activeArticleId && (
            <ArticlePage articleId={activeArticleId} isStudyPanel={false} />
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {!currentUser && (
        <div className="non-auth-library">
          <MSHeader />
          <div
            className={`non-auth-library_body ${activeArticleId ? 'is-article-page' : 'is-landing-page'}`}
          >
            {renderLibrary()}
          </div>
          <MSFooter />
        </div>
      )}
      {currentUser && (
        <Page type={activeArticleId ? 'library-landing' : ''}>
          {renderLibrary()}
        </Page>
      )}
    </>
  );
};

export default Library;
