import Modal from '@mui/material/Modal';
import { hapticsImpactLight } from '../../../utils/haptics';
import { updateFlashcardVariant } from '../../../services/flashcards';
import { useEffect, useState } from 'react';
import { FlashcardContent } from '../../../types/Flashcard';
import { replaceFlashcardVariantContentById } from '../../../slices/flashcards/flashcardsSlice';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import Tooltip from '@mui/material/Tooltip';

interface EditFlashcardProps {
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  flashcardId: string;
  activeFlashcardVariant: FlashcardContent | null;
  setKeybindsDisabled?: (isDisabled: boolean) => void;
}

const EditFlashcard: React.FC<EditFlashcardProps> = ({
  isEditing,
  setIsEditing,
  flashcardId,
  activeFlashcardVariant,
  setKeybindsDisabled,
}) => {
  const [currentFlashcardFront, setCurrentFlashcardFront] =
    useState<string>('');
  const [currentFlashcardBack, setCurrentFlashcardBack] = useState<string>('');
  const [currentFlashcardExplanation, setCurrentFlashcardExplanation] =
    useState<string>('');
  const [isMergedEditing, setIsMergedEditing] = useState<boolean>(false);
  const [currentFlashcardMerged, setCurrentFlashcardMerged] =
    useState<string>('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing && activeFlashcardVariant) {
      setCurrentFlashcardFront(activeFlashcardVariant.front);
      setCurrentFlashcardBack(activeFlashcardVariant.back);
      setCurrentFlashcardExplanation(activeFlashcardVariant.explanation);
    }
  }, [isEditing, activeFlashcardVariant]);

  const handleFrontTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCurrentFlashcardFront(event.target.value);
  };
  const handleBackTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCurrentFlashcardBack(event.target.value);
  };
  const handleExplanationTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCurrentFlashcardExplanation(event.target.value);
  };

  // Parsing function for the front field (expects cloze content in square brackets)
  const parseFrontClozeContent = (
    content: string
  ): { before: string; clozeContent: string; after: string } => {
    const regex =
      /^(.*?)<span class=['"]cloze['"]>\s*\[(.*?)\]\s*<\/span>(.*)$/s;
    const match = content.match(regex);
    if (match) {
      return {
        before: match[1],
        clozeContent: match[2],
        after: match[3],
      };
    } else {
      // No cloze span found
      return {
        before: content,
        clozeContent: '',
        after: '',
      };
    }
  };

  // Parsing function for the back field (expects cloze content without square brackets)
  const parseBackClozeContent = (
    content: string
  ): { before: string; clozeContent: string; after: string } => {
    const regex = /^(.*?)<span class=['"]cloze['"]>\s*(.*?)\s*<\/span>(.*)$/s;
    const match = content.match(regex);
    if (match) {
      return {
        before: match[1],
        clozeContent: match[2],
        after: match[3],
      };
    } else {
      // No cloze span found
      return {
        before: content,
        clozeContent: '',
        after: '',
      };
    }
  };

  const parseMergedContent = (
    content: string
  ): {
    before: string;
    frontClozeContent: string;
    backClozeContent: string;
    after: string;
  } => {
    const regex = /^(.*?)\{\{\s*(.*?)\s*::\s*(.*?)\s*\}\}(.*)$/s;
    const match = content.match(regex);
    if (match) {
      return {
        before: match[1],
        backClozeContent: match[2],
        frontClozeContent: match[3],
        after: match[4],
      };
    } else {
      // No merged cloze found
      return {
        before: content,
        frontClozeContent: '',
        backClozeContent: '',
        after: '',
      };
    }
  };

  const generateMergedContent = (): boolean => {
    const frontParsed = parseFrontClozeContent(currentFlashcardFront);
    const backParsed = parseBackClozeContent(currentFlashcardBack);

    // Compare before and after content
    if (
      frontParsed.before.trim() !== backParsed.before.trim() ||
      frontParsed.after.trim() !== backParsed.after.trim()
    ) {
      enqueueSnackbar('Front and Back fields do not match.', {
        autoHideDuration: 3000,
      });
      return false;
    }

    const mergedContent = `${frontParsed.before}{{${backParsed.clozeContent}::${frontParsed.clozeContent}}}${frontParsed.after}`;

    setCurrentFlashcardMerged(mergedContent);
    return true;
  };

  const saveMergedContent = () => {
    const mergedParsed = parseMergedContent(currentFlashcardMerged);
    if (
      !mergedParsed.frontClozeContent ||
      !mergedParsed.backClozeContent ||
      mergedParsed.before === undefined ||
      mergedParsed.after === undefined
    ) {
      alert('Merged content is invalid.');
      return;
    }

    const frontContent = `${mergedParsed.before}<span class='cloze'>[${mergedParsed.frontClozeContent}]</span>${mergedParsed.after}`;
    const backContent = `${mergedParsed.before}<span class='cloze'>${mergedParsed.backClozeContent}</span>${mergedParsed.after}`;

    setCurrentFlashcardFront(frontContent);
    setCurrentFlashcardBack(backContent);
    enqueueSnackbar('Front and Back updated.', {
      autoHideDuration: 3000,
    });
    setIsMergedEditing(false);
  };

  const submitVariantUpdate = async () => {
    if (!activeFlashcardVariant) return;

    let frontToUpdate = currentFlashcardFront;
    let backToUpdate = currentFlashcardBack;

    if (isMergedEditing) {
      const mergedParsed = parseMergedContent(currentFlashcardMerged);
      if (
        !mergedParsed.frontClozeContent ||
        !mergedParsed.backClozeContent ||
        mergedParsed.before === undefined ||
        mergedParsed.after === undefined
      ) {
        alert('Merged content is invalid.');
        return;
      }

      frontToUpdate = `${mergedParsed.before}<span class='cloze'>[${mergedParsed.frontClozeContent}]</span>${mergedParsed.after}`;
      backToUpdate = `${mergedParsed.before}<span class='cloze'>${mergedParsed.backClozeContent}</span>${mergedParsed.after}`;
    }

    const updatedVariant = {
      front: frontToUpdate,
      back: backToUpdate,
      explanation: currentFlashcardExplanation,
      isQAed: true,
    };
    const updatedFlashcard = await updateFlashcardVariant(
      // @ts-expect-error - activeFlashcardVariant is not null
      activeFlashcardVariant.id,
      updatedVariant
    );
    if (updatedFlashcard) {
      dispatch(
        replaceFlashcardVariantContentById({
          flashcardId: flashcardId,
          variantId: updatedFlashcard.id,
          newFront: updatedFlashcard.front,
          newBack: updatedFlashcard.back,
          newExplanation: updatedFlashcard.explanation,
          newIsQAed: updatedFlashcard.isQAed,
        })
      );
    }
    enqueueSnackbar('Flashcard updated successfully.', {
      autoHideDuration: 3000,
    });
    setIsEditing(false);
    if (setKeybindsDisabled) {
      setKeybindsDisabled(false);
    }
  };

  return (
    <Modal
      open={isEditing}
      onClose={() => {
        setIsEditing(false);
        setIsMergedEditing(false);
        if (setKeybindsDisabled) {
          setKeybindsDisabled(false);
        }
      }}
    >
      <div className="modal modal--glass modal--edit-flashcard">
        <div className="modal_header">
          <h2>Edit Flashcard</h2>
        </div>
        <div className="modal_content">
          <form>
            {!isMergedEditing && (
              <div className="row">
                <div className="editor">
                  <label htmlFor="editor--flashcard-front">Front</label>
                  <textarea
                    id="editor--flashcard-front"
                    value={currentFlashcardFront}
                    onChange={handleFrontTextareaChange}
                  ></textarea>
                </div>
                <div className="editor">
                  <label htmlFor="editor--flashcard-back">Back</label>
                  <textarea
                    id="editor--flashcard-back"
                    value={currentFlashcardBack}
                    onChange={handleBackTextareaChange}
                  ></textarea>
                </div>
              </div>
            )}
            {isMergedEditing && (
              <div className="row">
                <div className="editor">
                  <label htmlFor="editor--flashcard-merged">Merged</label>
                  <textarea
                    id="editor--flashcard-merged"
                    value={currentFlashcardMerged}
                    onChange={(e) => setCurrentFlashcardMerged(e.target.value)}
                  ></textarea>
                </div>
              </div>
            )}
            <div className="row">
              <button
                type="button"
                onClick={() => {
                  if (!isMergedEditing) {
                    const mergeSuccessful = generateMergedContent();
                    if (mergeSuccessful) {
                      setIsMergedEditing(true);
                    }
                  } else {
                    setIsMergedEditing(false);
                  }
                }}
              >
                {isMergedEditing ? 'Edit Front/Back' : 'Edit Merged'}
              </button>
              {isMergedEditing && (
                <button
                  type="button"
                  onClick={saveMergedContent}
                  style={{ marginLeft: 'auto' }}
                >
                  Save
                </button>
              )}
            </div>
            <div className="row">
              <div className="editor">
                <label htmlFor="editor--flashcard-explanation">
                  Explanation
                </label>
                <textarea
                  id="editor--flashcard-explanation"
                  value={currentFlashcardExplanation}
                  onChange={handleExplanationTextareaChange}
                ></textarea>
              </div>
            </div>
          </form>
        </div>
        <div className="modal_actions">
          <button
            className="button button--glass button--back"
            onClick={() => {
              setIsEditing(false);
              setIsMergedEditing(false);
              if (setKeybindsDisabled) {
                setKeybindsDisabled(false);
              }
              hapticsImpactLight();
            }}
          >
            Cancel
          </button>
          <Tooltip
            title={
              isMergedEditing ? 'Please save your merge changes first.' : ''
            }
          >
            <span>
              <button
                type="submit"
                className="button button--glass button--submit"
                onClick={() => {
                  setIsMergedEditing(false);
                  hapticsImpactLight();
                  submitVariantUpdate();
                }}
                disabled={isMergedEditing}
              >
                Submit
              </button>
            </span>
          </Tooltip>
        </div>
      </div>
    </Modal>
  );
};

export default EditFlashcard;
