import { createBrowserRouter } from 'react-router-dom';
import ErrorPage from '../ErrorPage';
import Home from '../pages/Home';
import Admin from '../pages/Admin';
import Auth from '../pages/Auth';
import ProtectedLayout from './ProtectedLayout';
import Study from '../pages/Study';
import ResetPassword from '../pages/Auth/ResetPassword';
import Onboarding from '../pages/Onboarding';
import Library from '../pages/Library';
import EmailPreferences from '../pages/EmailPreferences';
import Dashboard from '../pages/Dashboard';
import PastBlocks from '../pages/Dashboard/PastBlocks';
import Priorities from '../pages/Dashboard/Priorities';
import Account from '../pages/Account';
import Roadmap from '../pages/Roadmap';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <ProtectedLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      { path: 'onboarding', element: <Onboarding /> },
      { path: 'admin', element: <Admin /> },
      { path: 'study', element: <Study /> },
      { path: 'account', element: <Account /> },
      { path: 'performance', element: <Dashboard /> },
      { path: 'priorities', element: <Priorities /> },
      { path: 'past-blocks', element: <PastBlocks /> },
    ],
  },
  {
    path: 'roadmap',
    element: <Roadmap />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'library',
    element: <Library />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'email-preferences',
    element: <EmailPreferences />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'library/:articleId',
    element: <Library />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'auth',
    element: <Auth />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'reset',
    element: <ResetPassword />,
    errorElement: <ErrorPage />,
  },
]);
