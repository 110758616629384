import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ArrowRight, Info } from 'react-feather';
import {
  fetchQbankPerformanceStats,
  fetchTopicPriorities,
} from '../../services/stats';
import { useHotkeys } from 'react-hotkeys-hook';
import QBankPerformance from '../../components/Dashboard/QBankPerformance';
import { hapticsImpactLight } from '../../utils/haptics';
import { RootState } from '../../store/store';
import Heatmap from '../../components/Dashboard/Heatmap';
import DonutChart from 'react-donut-chart';
import {
  fetchAllExamSessions,
  fetchFirstStudySession,
} from '../../services/studySession';
import moment from 'moment';
import { StudySession } from '../../types/Study';
import { fetchVignetteCompletion } from '../../services/vignettes';
import { fetchFlashcardCompletion } from '../../services/flashcards';
import { UserExam } from '../../types/User';
// import CircularProgress from '@mui/material/CircularProgress';
import FlashcardTrend from '../../components/Dashboard/FlashcardTrend';
import Skeleton from '@mui/material/Skeleton';
import { Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Page from '../../components/Page';
import { useUpdateTheme } from '../../hooks/useUpdateTheme';

interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = () => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const currentUserExams =
    useSelector((state: RootState) => state.auth.userInfo?.exams) || [];
  const primaryExam = currentUserExams.find((exam) => exam.primary);
  const [qbankPerformanceData, setQbankPerformanceData] = useState(null);
  const [flashcardTrendData, setFlashcardTrendData] = useState<
    { date: string; completedFlashcardCount: number; isDiagnostic: boolean }[]
  >([]);
  const [progress, setProgress] = useState(0);
  const [daysRemaining, setDaysRemaining] = useState(0);
  const [vignettesSeen, setVignettesSeen] = useState(0);
  const [flashcardsSeen, setFlashcardsSeen] = useState(0);
  const [topicPriorities, setTopicPriorities] = useState([]);
  const [vignetteCompletionPercentage, setVignetteCompletionPercentage] =
    useState(0);
  const [flashcardCompletionPercentage, setFlashcardCompletionPercentage] =
    useState(0);

  const navigate = useNavigate();
  const { updateTheme } = useUpdateTheme();

  useHotkeys(
    'esc',
    () => {
      navigate('/');
    },
    { keyup: true }
  );
  useHotkeys(
    't',
    () => {
      if (currentUser) {
        updateTheme(!currentUser?.darkMode);
      }
    },
    { keyup: true }
  );

  useEffect(() => {
    if (primaryExam) {
      calculateStudyProgress(primaryExam);
      calculateContentCompletion(primaryExam);
      getPriorityTopics();
      fetchQbankPerformanceStats().then((response) => {
        setQbankPerformanceData(response);
      });
      fetchAllPastStudySessions();
    }
  }, [primaryExam]);

  const calculateStudyProgress = async (primaryExam: UserExam) => {
    if (primaryExam && primaryExam.examDate) {
      fetchFirstStudySession(primaryExam.id).then((response) => {
        if (!response || !response?.date) return;
        const endDate = moment(primaryExam.examDate);
        const startDate = moment(response.date);
        const currentDate = moment();
        const totalDays = endDate.diff(startDate, 'days');
        const dRemaining = endDate.diff(currentDate, 'days');
        const progress = 100 - (dRemaining / totalDays) * 100;
        setDaysRemaining(dRemaining);
        setProgress(progress);
      });
    }
  };

  const fetchAllPastStudySessions = async () => {
    if (primaryExam) {
      fetchAllExamSessions(primaryExam.id).then((response) => {
        if (response) {
          const sessions = [...response];
          const data = sessions.map((session: StudySession) => {
            return {
              date: session.date,
              completedFlashcardCount: session.completedFlashcardCount,
              isDiagnostic: session.isDiagnostic,
            };
          });
          setFlashcardTrendData(data);
        }
      });
    }
  };

  const calculateContentCompletion = async (primaryExam: UserExam) => {
    if (currentUser && primaryExam) {
      if (primaryExam.isUsingQbank) {
        fetchVignetteCompletion(currentUser.id, primaryExam.id).then(
          (response) => {
            if (response) {
              setVignettesSeen(response.seenVignetteCount);
              setVignetteCompletionPercentage(response.percentCompleted);
            }
          }
        );
      }
      if (primaryExam.isUsingFlashcards) {
        fetchFlashcardCompletion(currentUser.id, primaryExam.id).then(
          (response) => {
            if (response) {
              setFlashcardsSeen(response.seenFlashcardCount);
              setFlashcardCompletionPercentage(response.percentCompleted);
            }
          }
        );
      }
    }
  };

  const getPriorityTopics = async () => {
    fetchTopicPriorities().then((response) => {
      setTopicPriorities(response);
    });
  };

  const renderTopicPrioritiesTile = () => {
    return (
      <div className="block block--dashboard block--dashboard--priorities">
        <div className="block_body">
          <h2 className="m-t-0">
            <span>
              <span>Topic</span> Priorities
            </span>
            <Tooltip
              title={
                'The highest priority topics based on your performance so far. These change as you study with Ora.'
              }
              enterTouchDelay={0}
            >
              <Info />
            </Tooltip>
          </h2>
          <div className="priority-topics">
            <div className="priority-topics_wrapper">
              {topicPriorities.length > 0 &&
                topicPriorities
                  .slice(0, 3)
                  .map(({ priority, path, name, articleId }, index) => {
                    return (
                      <div
                        key={'tile-' + name + '-' + path + '-' + priority}
                        className="priority-topics_wrapper_row"
                      >
                        <div className="left">
                          <div className="number">{index + 1}</div>
                        </div>
                        <div className="right">
                          <div className="l1">{path}</div>
                          <div className="l2">
                            {articleId ? (
                              <a
                                className="article-link"
                                href={`/library/${articleId}`}
                              >
                                {name}
                                <ArrowRight />
                              </a>
                            ) : (
                              <>{name}</>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              {!topicPriorities.length && (
                <>
                  {Array.from({ length: 3 }).map((_, index) => (
                    <Skeleton
                      key={index}
                      sx={{
                        bgcolor: 'rgba(255,255,255,.05)',
                        borderRadius: '1rem',
                      }}
                      variant="rectangular"
                      animation="wave"
                      width={'100%'}
                      height={32}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
          <button
            className="button button--link--glass"
            onClick={() => {
              navigate('/priorities');
              hapticsImpactLight();
            }}
          >
            <span>View Full List</span>
            <ArrowRight />
          </button>
        </div>
      </div>
    );
  };

  const areAllValuesDefined = (...values: (number | null | undefined)[]) =>
    values.every((value) => value !== undefined && value !== null);

  return (
    <Page>
      <div className="dashboard">
        <div className="dashboard_wrapper">
          <div className="exam-title">
            <span>{primaryExam?.name || ''}</span>
            <h1>Performance</h1>
          </div>
          {primaryExam?.examDate && !primaryExam?.isDefaultDate && (
            <div
              className="dashboard_wrapper_progress"
              style={daysRemaining > 0 ? {} : { marginTop: '.75rem' }}
            >
              {daysRemaining > 0 && (
                <div className="dashboard_wrapper_progress_label">
                  {daysRemaining} day{daysRemaining !== 1 ? 's' : ''} remaining.{' '}
                  {/* <span>You're {progress === 0 ? '' : 'still'} on track!</span> */}
                  {progress > 0 && <span>Keep up the great work!</span>}
                </div>
              )}
              <div className="dashboard_wrapper_progress_bar">
                <div
                  className="dashboard_wrapper_progress_bar_inner"
                  style={{ width: progress + '%' }}
                ></div>
              </div>
            </div>
          )}
          <div className="dashboard_wrapper_body">
            <div className="dashboard_wrapper_body_main">
              {primaryExam?.isUsingQbank && (
                <QBankPerformance
                  data={qbankPerformanceData}
                  showPreviousBlocks={() => navigate('/past-blocks')}
                />
              )}
              {!primaryExam?.isUsingQbank && (
                <FlashcardTrend data={flashcardTrendData} />
              )}
            </div>
            <div className="dashboard_wrapper_body_panel">
              <div className="block block--dashboard">
                <div className="block_body">
                  <h2 className="m-t-0">
                    <span>Content</span> Completion
                  </h2>
                  {primaryExam && (
                    <div className="mastery-rings">
                      {primaryExam.isUsingQbank && (
                        <>
                          {areAllValuesDefined(
                            vignetteCompletionPercentage,
                            vignettesSeen,
                            primaryExam.selectedVignetteCount
                          ) && (
                            <div className="donut">
                              <DonutChart
                                height={120}
                                width={120}
                                data={[
                                  {
                                    label: 'Correct',
                                    value: vignetteCompletionPercentage,
                                  },
                                  {
                                    label: '',
                                    value: 100 - vignetteCompletionPercentage,
                                  },
                                ]}
                                colors={[
                                  'var(--green)',
                                  'rgba(255,255,255,.1)',
                                ]}
                                strokeColor={'transparent'}
                                innerRadius={0.75}
                                selectedOffset={0}
                                legend={false}
                                interactive={false}
                              />
                              <div className="donut_label">
                                <div className="donut_label_completed">
                                  {vignettesSeen}
                                </div>
                                <div className="donut_label_total">
                                  {primaryExam.selectedVignetteCount}
                                </div>
                              </div>
                              <div className="donut_outer-label">QBank</div>
                            </div>
                          )}
                          {!areAllValuesDefined(
                            vignetteCompletionPercentage,
                            vignettesSeen,
                            primaryExam.selectedVignetteCount
                          ) && (
                            <Skeleton
                              sx={{
                                bgcolor: 'rgba(255,255,255,.05)',
                                borderRadius: '1rem',
                              }}
                              variant="rectangular"
                              animation="wave"
                              width={120}
                              height={140}
                            />
                          )}
                        </>
                      )}
                      {primaryExam.isUsingFlashcards && (
                        <>
                          {areAllValuesDefined(
                            flashcardCompletionPercentage,
                            flashcardsSeen,
                            primaryExam.selectedFlashcardCount
                          ) && (
                            <div className="donut">
                              <DonutChart
                                height={120}
                                width={120}
                                data={[
                                  {
                                    label: 'Correct',
                                    value: flashcardCompletionPercentage,
                                  },
                                  {
                                    label: '',
                                    value: 100 - flashcardCompletionPercentage,
                                  },
                                ]}
                                colors={[
                                  'var(--green)',
                                  'rgba(255,255,255,.1)',
                                ]}
                                strokeColor={'transparent'}
                                innerRadius={0.75}
                                selectedOffset={0}
                                legend={false}
                                interactive={false}
                              />
                              <div className="donut_label">
                                <div className="donut_label_completed">
                                  {flashcardsSeen}
                                </div>
                                <div className="donut_label_total">
                                  {primaryExam.selectedFlashcardCount}
                                </div>
                              </div>
                              <div className="donut_outer-label">
                                Flashcards
                              </div>
                            </div>
                          )}
                          {!areAllValuesDefined(
                            flashcardCompletionPercentage,
                            flashcardsSeen,
                            primaryExam.selectedFlashcardCount
                          ) && (
                            <Skeleton
                              sx={{
                                bgcolor: 'rgba(255,255,255,.05)',
                                borderRadius: '1rem',
                              }}
                              variant="rectangular"
                              animation="wave"
                              width={120}
                              height={140}
                            />
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {primaryExam && (
                <div className="block block--dashboard">
                  <div className="block_body">
                    <h2 className="m-t-0">
                      <span>Study</span> Streak
                    </h2>
                    <Heatmap examId={primaryExam.id} />
                  </div>
                </div>
              )}
              {primaryExam?.isUsingQbank && renderTopicPrioritiesTile()}
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Dashboard;
